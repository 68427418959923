
import { ILoginPayload } from 'kwik-vue'
import { dispatchKwikLogIn } from 'kwik-vue/store/main/actions'
import { readKwikLoginError } from 'kwik-vue/store/main/getters'
import Vue from 'vue'

export default Vue.extend({
  name: 'KwikLogin',
  data () {
    return {
      email: '',
      password: ''
    }
  },
  computed: {
    loginError () {
      return readKwikLoginError(this.$store)
    }
  },
  methods: {
    submit () {
      const loginPayload: ILoginPayload = {
        username: this.email,
        password: this.password
      }
      dispatchKwikLogIn(this.$store, loginPayload)
    },
    getAppName (): string {
      const appName = process.env.VUE_APP_NAME as string
      if (appName) return appName
      return ''
    }
  }
})
